<template>
  <div>
    <div
      v-if="generalMsg.text != ''"
      class="top-banner"
      :style="
        `background-color:` + generalMsg.background_color + `; color:` + generalMsg.text_color + `;`
      "
    >
      <span class="texttop" v-html="generalMsg.text"></span>
    </div>
    <router-view />
    <div class="c-toaster-top-full">
      <div class="c-toaster-slot" id="c-toaster-slot"></div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, watch, onUpdated, nextTick, onServerPrefetch } from "vue";
import { useStore } from "vuex";
import { createToast } from "@storefront/core/helpers";
import config from "@config";
import { useHead } from "@unhead/vue";
import { useGtm } from "@gtm-support/vue-gtm";
import VueCookies from "vue-cookies";
import { useRouter } from "vue-router";

// Use store in setup
const store = useStore();
const gtm = useGtm();

const contentList = [
  "side-menu-cms",
  "footer_menu_information",
  "footer_menu_service",
  "footer_menu_quick_links",
  "footer_contact",
  "order_today_tomorrow_at_home",
  "free_shipping",
  "our_advantages",
  "home_pictures",
  "header_seperator_title",
  "contact_page_block",
  "merken",
  "footer_copyright",
  "bundle_tips_alternative",
  "checkout_succes",
];

const sliders = [
  "home",
  "home_gallery",
  "payment_providers",
  "delivery_partners",
  "discount_header",
  "discount_header_mobile",
];

if (typeof window !== "undefined") {
  const websiteCookie = VueCookies.get("websiteCookie");
  if (websiteCookie === "true") {
    gtm.enable(true);
    gtm.debug(true);
  }
}

// State and computed properties
const generalMsg = computed(() => store.state.messages.generalMsg);
// const cmsDefaultPageContent = computed(() => store.state.cmsPage.default);
const currentTemplate = config.template.name;

// Set up meta information using useHead
useHead({
  titleTemplate: `%s | ${config.app_name}`,
  htmlAttrs: { lang: store.getters["i18n/locale"] },
  meta: [
    { charset: "utf-8" },
    { name: "viewport", content: "width=device-width, initial-scale=1" },
  ],
});

// Watchers
watch(
  () => store.state.messages.msgItems.length,
  async () => {
    const msgs = await store.dispatch("messages/giveLastMessages");
    msgs.forEach((msg) => {
      createToast(msg);
    });
  }
);

const router = useRouter();

async function fetchData() {
  store.dispatch("forms/loadForms");
  store.dispatch("faq/load");
  await Promise.allSettled([
    store.dispatch("messages/loadGeneralMsg"),
    store.dispatch("cmsPage/loadDefault", { id: "home" }),
    store.dispatch("sliders/multiple", { key: "identifier", value: sliders }),
    store.dispatch("stores/load"),
    store.dispatch("product/loadBrandSlider"),
    store.dispatch("menu/loadMenu"),
    store.dispatch("user/loadCountries"),
    store.dispatch("product/productReviewRatingsMetadata"),
    store.dispatch("home/loadBundles", { store }),
    store.dispatch("cmsBlock/multiple", { key: "identifier", value: contentList }),
    store.dispatch("cart/loadPickupLocations"),
  ]);
}

// Lifecycle hooks
onServerPrefetch(fetchData);

onMounted(() => {
  window.redirect = redirect;
  if (currentTemplate === "esf_thehague_fietskledingvoordeel") {
    const app = document.getElementById("app");
    if (!app.classList.contains("fkv")) {
      app.classList.add("fkv");
    }
  }

  store.dispatch("user/loadUser", { store });
  if (!store.getters["cart/getCartIsLoaded"]) {
    store.dispatch("cart/loadCart", { store });
  }

  if (config.helloRetail && config.helloRetail.enabled) {
    let helloRetail = document.createElement("script");
    helloRetail.async = true;
    if (config.helloRetail.code) {
      loadHelloRetail();
    }
  }
});

onUpdated(() => {
  if (currentTemplate === "esf_thehague_fietskledingvoordeel") {
    const app = document.getElementById("app");
    if (!app.classList.contains("fkv")) {
      app.classList.add("fkv");
    }
  }

  nextTick(() => {
    if (self.ADDWISH_PARTNER_NS) {
      self.ADDWISH_PARTNER_NS.api.reload();
    } else {
      loadHelloRetail();
    }
  });
});

function redirect(e) {
  e?.preventDefault();
  router.push(e?.currentTarget.getAttribute("href"));
}

function loadHelloRetail() {
  let helloRetail = document.createElement("script");
  helloRetail.async = true;
  if (config.helloRetail && config.helloRetail.code) {
    helloRetail.src =
      "https://d1pna5l3xsntoj.cloudfront.net/scripts/company/awAddGift.js#" +
      config.helloRetail.code;
    document.head.appendChild(helloRetail);
  }
}
</script>

<style lang="scss">
#app {
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: 100vh;

  .modal-open & {
    width: 100vw;
  }
}

.texttop {
  display: inline-block;
  position: relative;
  margin-top: 5px;
  margin-bottom: -15px;
}

.top-banner {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.22rem;
  position: relative;
}

a {
  transition: 0.3s color ease-in-out;
}

.resize-active * {
  transition: none !important;
}
.best-seller--category {
  font-size: 24px;
  letter-spacing: -0.025em;
  font-weight: normal;
  display: inline-block;
  vertical-align: top;
  background: $black;
  color: $white;
  padding: 6px 10px;
  min-width: 213px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
}

.section-title {
  font-size: 18px;
  font-weight: normal;
  display: block;
  text-align: center;
  margin-bottom: 19px;
}
</style>
